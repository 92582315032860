<template>

  <router-link class="widget-item news-item grid" :to="`/news/` + item.slug">
    <div class="news-item__img" 
      :style="{
          backgroundImage : `url(${fullPath(encodeURI(item.annotation_img))})`
        }"></div>
    <div class="news-item__text grid">
      <div class="news-item__name">{{item.name}}</div>
      <div class="news-item__title">{{item.annotation_text}}</div>
    </div>
  </router-link>

</template>

<script>
  import { fullPath } from '@/helpers/fullPath'
  export default {
    name: 'NewsItemMainView',
    props: ['item'],
    setup(){
      return {
        fullPath
      }
    }
 }
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "vue-slider-component/theme/default.css"

.news-item
  text-decoration: none
  color: $fc_1
  width: 450px
  height: 200px
  grid-template-columns: 180px auto
  grid-gap: 15px
  align-items: center
  @media (max-width: $size_m)
    grid-template-columns: auto
    grid-template-rows: 230px auto
    width: 250px
    height: 100%
    grid-gap: 0px
  &__img
    width: 100%
    height: 100%
    aspect-ratio: 1/1
    // @media (max-width: $size_m)
    //   height: 150px
    background-position: center
    background-size: cover
    border-radius: 12px
  &__name
    padding: 20px 0 20px 0
    font-size: 16px
    @media (max-width: $size_s)
      padding: 10px 0 10px 0
  &__title
    display: none
    padding: 10px
    font-size: 18px
    color: $fc_1
    @media (max-width: $size_m)
      display: none

</style>
