import api from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/css-loader/dist/cjs.js!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-4.use[3]!../../node_modules/vue-loader/dist/index.js??ruleSet[1].rules[18].use[0]!./VideoItem.vue?vue&type=style&index=0&id=6c09473a&lang=sass&scoped=true";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};