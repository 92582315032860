<template>
  <div class="MainContainer grid">
    <div class="ad" id="ad_wrapper">
      <!-- Yandex.RTB R-A-344963-1 -->
      <div id="yandex_rtb_R-A-344963-1"></div>
      <!--AdFox START-->
      <!--yandex_m10-->
      <!--Площадка: Питер FM / * / *-->
      <!--Тип баннера: 728x90-->
      <!--Расположение: верх страницы-->
      <!-- <div id="adfox_16503653816004712"></div> -->

      <div id="adfox_16505503761985124"></div>


    </div>
    <header class="header grid" id="mainHeader">
      <PlayerComponent></PlayerComponent>
    </header>
    <router-view></router-view>
  </div>
  <footer class="footer grid">
    <div class="grid footer__blk-1">
      <div>
        <p class="footer__blk_title">Отдел рекламы</p>
        <a href="tel:88124674047">Телелефон: (812) 467-40-47</a>
        <br>
        <a href="mailto:reklama@m10m.ru">E-mail: reklama@m10m.ru</a>
      </div>
      <div>
        <p class="footer__blk_title">Адрес</p>
        <p class="footer__blk_adress">ул. Шевченко, д. 27<br>
        Санкт-Петербург,
        Россия, 199406</p>
      </div>
    </div>
    <div class="grid footer__blk-2">
      <a href="https://m10m.ru/" target="_blank"><img src="@/assets/img/media-logo.svg" alt=""></a>
    </div>
    <div class="grid footer__blk-3">
      <a class="footer__blk_store" target="_blank" href="https://apps.apple.com/us/app/%D0%BF%D0%B8%D1%82%D0%B5%D1%80-fm/id1460568149">
        <img src="@/assets/img/icons/appstore.svg">
      </a>
      <a class="footer__blk_store" target="_blank" href="https://play.google.com/store/apps/details?id=piterfm.radio&referrer=appmetrica_tracking_id%3D96934151940211310%26ym_tracking_id%3D8880047669519415319">
        <img src="@/assets/img/icons/googleplay.svg">
      </a>
    </div>
  </footer>
</template>

<script>
import PlayerComponent from '/components/PlayerComponent.vue'
export default {
  name: 'MainContainer',
  components: {
    PlayerComponent
  },
  setup() {
    window.yaContextCb.push(()=>{
      Ya.Context.AdvManager.render({
        renderTo: 'yandex_rtb_R-A-344963-1',
        blockId: 'R-A-344963-1'
      })})
    return {
    }
    
    // window.yaContextCb.push(()=>{
    //     Ya.adfoxCode.create({
    //         ownerId: 286942,
    //         containerId: 'adfox_16503653816004712',
    //         params: {
    //             pp: 'g',
    //             ps: 'fqge',
    //             p2: 'glnz'
    //         }
    //     })
    // })


    // window.yaContextCb.push(()=>{
    //   Ya.adfoxCode.create({
    //     ownerId: 286942,
    //     containerId: 'adfox_16505503761985124',
    //     params: {
    //       pp: 'g',
    //       ps: 'fqge',
    //       p2: 'glnz'
    //     }
    //   })
    // })
  }
}

</script>
<style lang="sass">
@import "/styles/var.sass"

.MainContainer
  grid-template-columns: minmax(0,1fr)
  grid-template-rows:  max-content max-content minmax(0,1fr)
  background-color: $bglvl_1
  border-radius: 0px 0px 20px 20px
  padding: 0 20px 50px 20px
  height: max-content
  @media (max-width: $size_m)
    grid-template-rows: max-content max-content minmax(0,1fr)
    padding-left: 10px
    padding-right: 10px

  .ad
    width: 100%
    background-color: rgba(10,10,10,0.6)
    box-shadow: 0px 0px 10px -3px #000

.header
  z-index: 9999
  width: 100%
  position: sticky
  top: 0
  border-radius: 0 0 20px 20px
  backdrop-filter: saturate(180%) blur(10px)
  background-color: rgba(10,10,10,0.6)
  box-shadow: 0px 0px 10px -3px #000
  margin-bottom: 20px

.footer
  height: max-content
  padding: 20px
  margin-bottom: 10px
  grid-template-columns: 1fr 1fr 1fr
  grid-gap: 20px
  grid-column: 2/3
  @media (max-width: $size_m)
    grid-column: 1/2
    grid-template-columns: 1fr 
    grid-gap: 40px
  &__blk-1
    color: $fc_2
    cursor: default
    align-items: center
    @media (max-width: $size_m)
      text-align: center
    a
      text-decoration: none
      color: $fc_2
      &:hover
        color: $fc_0
        transition: .2s
  &__blk_title
    margin: 10px 0 10px 0
  &__blk_adress
    margin: 0
  &__blk-2
    justify-items: center
    align-items: center
    img
      width: 180px
      filter: grayscale(80%)
      &:hover
        transition: .5s
        filter: grayscale(0%)
  &__blk-3
    justify-items: right
    align-self: center
    grid-gap: 20px
    @media (max-width: $size_m)
      grid-template-columns: 1fr 1fr
      justify-items: center
      justify-self: center
  &__blk_store
    cursor: pointer
    height: 40px
    img
      height: 100%
      filter: brightness(70%)
      &:hover
        filter: brightness(90%)

</style>
