import useApi from '@/hooks/api'
import { ref } from 'vue'

export interface Podcasts {
  items: Pod[],
  count: number
}

export interface Pod {
  id: number,
  name: string,
  description: string,
  img: string,
  yandex_id: links[]
}

export interface links {
  yandex: string,
  spotify: string,
  apple: string,
  google: string,
}

export default async function getPodcasts(offset,limit) {
  const { response: podcasts, request } = useApi<Podcasts>(
    `/api/v1/podcasts?offset=${offset}&limit=${limit}`
  )
  // console.log(podcasts)
  const loaded = ref(false)
  if (loaded.value === false) {
    await request()
    loaded.value = true
  }

  return { podcasts }
}
