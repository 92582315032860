<template>
  <div class="widget-item video-item grid" @click="modalVideoVisible = true">
    <div class="video-item__img"
        :style="{
          backgroundImage : 'url(' + encodeURI(item.preview_img) + ')'
        }">
    </div>
    <p class="video-item__name">{{item.name}}</p> 
    <VideoModal
      v-if="modalVideoVisible"
      @closeVideoModal="modalVideoVisible=false"
    >  
      <iframe :src="item.youtube_embed" frameborder="0" width="100%" height="100%"></iframe>
    </VideoModal>
  </div>
</template>

<script>
  import VideoModal from '@/components/VideoModal.vue'
  import { ref } from 'vue'
  export default {
    name: 'VideoItem',
    props: ['item'],
    components: {VideoModal},
    setup(){
      const modalVideoVisible = ref(false)

      return { 
        modalVideoVisible
      }
    },
  }
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "vue-slider-component/theme/default.css"

.video-item
  justify-items: center
  height: 100%
  &__name
    margin-left: 10px
    margin-right: 10px
    text-align: center
    color: $fc_1
  &__img
    width: 100%
    height: 180px
    border-radius: 12px
    overflow: hidden
    position: relative
    transition: .2s
    cursor: pointer
    background-position: center
    background-size: cover
    box-sizing: border-box
    &:before
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      border-radius: 10px
      background-color: $bglvl_0
      opacity: 0.3
      transition: .2s
    &:hover:before
      transition: .2s
      opacity: 0.0
    &:after
      transition: .2s
      display: block
      content: ''
      width: 70px
      height: 70px
      position: absolute
      top: 50%
      left: 50%
      transform: translateX(-50%) translateY(-50%)
      background-image: url(@/assets/img/player/play.svg)
      filter: invert(100%) drop-shadow($accent_shadow)

.video-item__href
  text-decoration: none
  font-size: 30px
  font-weight: 700
  color: $fc_0
  margin: auto
  transition: .2s
  &:hover
    transition: .2s
    text-shadow: $accent_shadow

</style>
