import App from '@/App.vue'
import { createApp } from 'vue'
import { router } from '@/router.ts'
import { createMetaManager } from 'vue-meta'
import store from '@/store'

const app = createApp(App)
const metaManager = createMetaManager()

app.use(router)
    .use(metaManager)
    .use(store)
    .mount('#app')
