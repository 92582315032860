import useApi from '@/hooks/api'
import { ref } from 'vue'

export interface Slides {
  items: Slide[]
}

export interface Slide {
  name: string,
  description: string,
  position: number,
  url: string,
  slide_image: string,
  polymorphic_url: string,
  imgPath: string
}

export async function getSlides() {
  const { response: slides, request } = useApi<Slides>(
    '/api/v1/slides/'
  )
  const loaded = ref(false)
  if (loaded.value === false) {
    await request()
    loaded.value = true
  }
  return { slides }
}
