<template>

  <teleport to="body">  
    <div class="mm__wrap grid">
      <div class="mm__close" @click.prevent="$emit('mm-close')"> 
        <MenuIcons :name="'menuCloseIcon'" /> 
      </div> 
      <nav class="nav grid">
        <router-link 
          v-for="page in pages" 
          :key="page.id" 
          :to="page.href" 
          class="grid nav__link"
        >
          <MenuIcons :name="page.icon" @click="$emit('mm-close')"/>
          <span class="nav__link_title" @click="$emit('mm-close')" >{{page.title}}</span>
        </router-link>
      </nav>
    </div>
 </teleport>

</template>


<script>
  import MenuIcons from '@/components/MenuIcons.vue'
  export default {
    name: 'MenuMobile',
    emits: ['mm-close'],
    components:{
      MenuIcons
    },
    setup() {
      const pages = [
      {id: 0, title: 'Главная', icon: 'mainLink', href:'/'},
      {id: 1, title: 'Новости', icon: 'newsLink', href:'/news'},
      {id: 2, title: 'Подкасты', icon: 'podcastLink', href:'/podcasts'},
      {id: 3, title: 'Видео', icon: 'vidoLink', href:'/video'},
      {id: 4, title: 'Реклама', icon: 'reklamaLink', href:'/advertising'},
      {id: 5, title: 'О нас', icon: 'infoLink', href:'/about'},
      // {id: 6, title: '#podcast#', icon: 'newsLink', href:'/podcasts/blank'},
    ]
      return {
        pages
      }
    }
 }
</script>


<style lang="sass" scoped>
@import "/styles/var.sass"
@import "vue-slider-component/theme/default.css"

.mm__wrap
  @media (min-width: $size_m) 
    display: none
  position: fixed
  top: 0
  left: 0
  height: max-content
  border-radius: 0 0 20px 0
  background-color: $bglvl_2
  box-shadow: 0px 0px 10px -3px #000
  backdrop-filter: saturate(180%) blur(10px)
  background-color: rgba(10,10,10,0.7)
  z-index: 99999
  grid-template-rows: 65px auto
  ::v-deep(svg)
    fill: $fc_0

.nav
  list-style: none
  padding: 0 20px 20px 20px
  grid-template-columns: auto
  grid-auto-rows: 35px
  &__link
    width: 150px
    cursor: pointer
    text-decoration: none
    color: $fc_1
    font-weight: 500
    font-size: 18px
    align-items: center
    grid-gap: 10px
    margin-left: -4px
    grid-template-columns: 24px auto
    ::v-deep(svg)
      fill: $fc_1
    &:hover
      transition: 0.2s
      color: $accent
      text-shadow: $accent_shadow
      svg
        fill: $accent
        transition: 0.2s
.active
  color: $fc_0
  text-shadow: $accent_shadow
  ::v-deep(svg)
    filter: drop-shadow($accent_shadow)
    fill: $fc_0 
    transition: 0.2s

.mm__close
  padding: 15px
  height: 35px

</style>
