import { render } from "./App.vue?vue&type=template&id=472cff63"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"

import "./App.vue?vue&type=style&index=0&id=472cff63&lang=sass"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "472cff63"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('472cff63', script)) {
    api.reload('472cff63', script)
  }
  
  module.hot.accept("./App.vue?vue&type=template&id=472cff63", () => {
    api.rerender('472cff63', render)
  })

}

script.__file = "App.vue"

export default script