import { render } from "./MenuIcons.vue?vue&type=template&id=1ff29ea0"
import script from "./MenuIcons.vue?vue&type=script&lang=js"
export * from "./MenuIcons.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1ff29ea0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1ff29ea0', script)) {
    api.reload('1ff29ea0', script)
  }
  
  module.hot.accept("./MenuIcons.vue?vue&type=template&id=1ff29ea0", () => {
    api.rerender('1ff29ea0', render)
  })

}

script.__file = "components/MenuIcons.vue"

export default script