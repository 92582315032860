import { ref } from "vue"
import { fullPath } from '@/helpers/fullPath'
export default function useApi (path: RequestInfo, options?: RequestInit | undefined){
  const response = ref()
  const request = async () => {
    const url = fullPath(path)
    response.value = await fetch(url, options).then(data => data.json()).catch(err => console.log(err))
  }
  
  return { response, request }
}
