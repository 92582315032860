import { render } from "./PlayerIcons.vue?vue&type=template&id=00a3eba4"
import script from "./PlayerIcons.vue?vue&type=script&lang=js"
export * from "./PlayerIcons.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "00a3eba4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('00a3eba4', script)) {
    api.reload('00a3eba4', script)
  }
  
  module.hot.accept("./PlayerIcons.vue?vue&type=template&id=00a3eba4", () => {
    api.rerender('00a3eba4', render)
  })

}

script.__file = "components/PlayerIcons.vue"

export default script