<template>
  <teleport to="body">
    <div class="modal__wrap grid" @click="$emit('closeVideoModal')">
      <div class="modal__bg"></div>
      <div class="modal__slot">
        <div class="modal__close" @click="$emit('closeVideoModal')">
          <svg width="35px" height="35px" fill="white" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"/></svg>
        </div>
        <div class="videoSlot">
          <slot></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>


<script>
  export default {
    name: 'VideoModal',
    props: {
    },
    emits:[
      'closeVideoModal'
    ]
  }
</script>


<style lang="sass" scoped>
@import "/styles/var.sass"
@import "vue-slider-component/theme/default.css"

.modal__wrap
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  justify-items: center
  align-items: center
  z-index: 99999

.modal__bg
  position: fixed
  width: 100%
  height: 100%
  background-color: black
  opacity: .5

.videoSlot
  z-index: 999999
  border-radius: 20px
  overflow: hidden
  width: 1440px
  height: 810px
  margin: 15px
  @media (max-width: 1440px)
    width: 960px
    height: 540px
  @media (max-width: $size_l)
    width: calc(100vw - 30px)
    height: calc((100vw - 30px) / 16 * 9)

.modal__slot
  position: relative
    
.modal__close
  position: absolute
  top: -20px
  right: 15px
  cursor: pointer
</style>
