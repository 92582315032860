<template>

  <div >
    <div class="component-wrapper ">
      <splide 
        :options="options" 
        :slides="newsItems"
      >
        <splide-slide 
          v-for="item in newsItems" 
          :key="item.name"
        >
          <NewsItemMainView :item="item"/>
        </splide-slide>
      </splide>
      <div class="component__link-wrap">
        <router-link to="/news" class="component__link">
          Все новости
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import NewsItemMainView from '@/components/NewsItemMainView.vue'
import getNews from '@models/news'
import { ref } from 'vue'

export default {
  name: 'NewsComponent',
  emits: ['loaded'],
  components: {
    Splide,
    SplideSlide,
    NewsItemMainView
  },
  setup (props,  { emit }) {
    const options = {
      rewind : true,
      autoWidth: true,
      pagination: false,
      arrows: true,
      gap:'20px'
    }
    const newsItems = ref([])
    const countNews = ref(0)
    const limitNews = 10

    getNews(countNews, limitNews)
      .then( (promise2Result) => {
        newsItems.value =  newsItems.value.concat(promise2Result.news.value.items)
        countNews.value += limitNews
        emit('loaded')
      })
      .catch(err => console.log(err))

    return {
      options,
      newsItems
    }
  },
}
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "@splidejs/splide/dist/css/themes/splide-default.min.css"

.component-wrapper
  margin-right: -20px
  margin-left: -10px
  @media (max-width: $size_m)
    margin-right: -10px

.component-wrapper::v-deep(.splide__track)
  padding: 10px 0px 10px 10px

.component-wrapper::v-deep(.splide__arrow)
  top: -30px 
  background-color: $bglvl_2
  svg
    fill: $fc_0
    transition: .2s
    &:hover
      fill: white
      transition: .2s
.component-wrapper::v-deep(.splide__arrow--prev)
  left: auto
  right: 50px

</style>
