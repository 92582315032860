<template>

  <div 
    class="preloader grid"
  >
    <img 
      class="preloader__img" 
      src="../assets/img/bars.svg"
    >
  </div>

</template>

<script setup>

</script>

<style lang="sass" scoped>
@import "/styles/var.sass"

.preloader
  width: 100%
  height: 300px
  justify-content: center
  align-content: center
  &__img
    width: 70px
</style>
