<template>
  <div class="VideoComponent">
    <div class="component-wrapper">
      <splide 
        :options="sploptions" 
        :slides="videoItems"
      >
        <splide-slide 
          v-for="item in videoItems" 
          :key="item.name"
        >
          <VideoItem :item="item"/>
        </splide-slide>
      </splide>
      <div class="component__link-wrap">
        <router-link to="/video" class="component__link">
          Все видео
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import getVideos from '@models/videos'
  import VideoItem from '@/components/VideoItem.vue'
  import { Splide, SplideSlide } from '@splidejs/vue-splide'
  export default {
    name: 'VideoComponent',
    emits: ['loaded'],
    components: {
      Splide,
      SplideSlide,
      VideoItem,
    },

    setup(props,  { emit }) {
      const sploptions = {
        rewind : true,
        autoWidth: true,
        pagination: false,
        arrows: true,
        gap:'20px'
      }
      
      const videoItems = ref([])

      getVideos(0,7)
        .then( (promise2Result) => {
          videoItems.value =  videoItems.value.concat(promise2Result.videos.value.items)
          emit('loaded')
        })
        .catch(err => console.log(err))
      
      return { videoItems, sploptions }
    }
  }
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "@splidejs/splide/dist/css/themes/splide-default.min.css"

.component-wrapper
  margin-left: -10px
  margin-right: -20px
  @media (max-width: $size_m)
    margin-right: -10px
  
.component-title
  font-size: 45px
  font-size: 18px
  @media (max-width: $size_m)
    font-size: 16px

.component-wrapper::v-deep(.splide__track)
  padding: 10px 0px 10px 10px
.component-wrapper::v-deep(.splide__slide)
  width: 320px
  @media (max-width: $size_m)
    width: 250px

.component-wrapper::v-deep(.splide__arrow)
  top: -30px 
  background-color: $bglvl_2
  // @media (max-width: $size_s)
  //   display: none
  svg
    fill: $fc_0
    transition: .2s
    &:hover
      fill: white
      transition: .2s
.component-wrapper::v-deep(.splide__arrow--prev)
  left: auto
  right: 50px
</style>
