import { render } from "./PreloaderView.vue?vue&type=template&id=872d6d20&scoped=true"
const script = {}

import "./PreloaderView.vue?vue&type=style&index=0&id=872d6d20&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-872d6d20"
/* hot reload */
if (module.hot) {
  script.__hmrId = "872d6d20"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('872d6d20', script)) {
    api.reload('872d6d20', script)
  }
  
  module.hot.accept("./PreloaderView.vue?vue&type=template&id=872d6d20&scoped=true", () => {
    api.rerender('872d6d20', render)
  })

}

script.__file = "components/PreloaderView.vue"

export default script