<template>

  <div class="menu-wrap">
    <router-link :to="'/'" class="menu__logo grid">
      <img src="../assets/img/logo_light.svg" class="menu__logo_img">
      <img src="../assets/img/logo_title.svg" class="menu__logo_img_small">
    </router-link>
    <nav class="nav grid">
      <router-link v-for="page in pages" :key="page.id" :to="page.href" class="grid nav__link">
        <MenuIcons :name="page.icon"/>
        <span class="nav__link_title">{{page.title}}</span>
      </router-link>
    </nav>
  </div>

</template>

<script>
import MenuIcons from '@/components/MenuIcons.vue'

export default {
  name: 'Menu',
  components: { MenuIcons },
  setup () {
    const pages = [
      {id: 0, title: 'Главная', icon: 'mainLink', href:'/'},
      {id: 1, title: 'Новости', icon: 'newsLink', href:'/news'},
      {id: 2, title: 'Подкасты', icon: 'podcastLink', href:'/podcasts'},
      {id: 3, title: 'Видео', icon: 'vidoLink', href:'/video'},
      {id: 4, title: 'Реклама', icon: 'reklamaLink', href:'/advertising'},
      {id: 5, title: 'О нас', icon: 'infoLink', href:'/about'},
      // {id: 6, title: '#podcast#', icon: 'newsLink', href:'/podcasts/blank'},
    ]
    return {
      pages
    }
  }
}
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "/styles/modal.sass"

.menu-wrap
  display: grid
  position: sticky
  height: 100vh
  @media (max-height: 450px)
    position: static
  top: 0
  grid-template-rows: 180px auto 120px
  @media (max-width: $size_l)
    grid-template-rows: 230px auto
    width: 60px 
  @media (max-width: $size_m)
    display: none
.menu__logo
  width: 100%
  justify-items: center
  align-items: center
  &_img
    width: 180px
    display: block
    @media (max-width: $size_l)
      display: none
      width: 0px
  &_img_small
    display: none
    @media (max-width: $size_l)
      display: block
      width: 35px
      padding: 20px 10px 20px 10px
.nav
  margin: 0
  margin-left: 50px
  list-style: none
  padding: 0
  grid-template-columns: auto
  grid-auto-rows: 35px
  @media (max-width: $size_l)
    margin: 0 auto 0 auto
    grid-gap: 10px
  &__link
    width: 150px
    cursor: pointer
    text-decoration: none
    color: $fc_2
    font-weight: 500
    font-size: 18px
    align-items: center
    grid-gap: 10px
    margin-left: -4px
    grid-template-columns: 24px auto
    ::v-deep(svg)
      fill: $fc_2
    @media (max-width: $size_l)
      width: 30px
      grid-template-columns: 50px
      ::v-deep(svg)
        height: 30px
        width: 30px
    &:hover
      transition: 0.2s
      color: $accent
      text-shadow: $accent_shadow
      ::v-deep(svg)
        fill: $accent
        transition: 0.2s
    &_title
      @media (max-width: $size_l)
        display: none
.active
  color: $fc_0
  text-shadow: $accent_shadow
  ::v-deep(svg)
    filter: drop-shadow($accent_shadow)
    fill: $fc_0 
    transition: 0.2s

.menu__adv-modal
  align-items: center
  justify-content: center
  button
    width: 170px
    height: 45px
    background-color: $bglvl_1
    border-radius: 10px
    transition: 0.2s
    font-weight: 500
    color: $fc_1
    @media (max-width: $size_l)
      width: 60px
    &:hover
      box-shadow: $accent_shadow
      transition: 0.2s
      color: $fc_0
</style>
