<template>

<!-- Иконки пунктов меню -->

  <svg v-if="name ==='mainLink'" width="24" height="24" viewBox="0 0 24 24" fill="#59595B" xmlns="http://www.w3.org/2000/svg"  ><path d="M19.266 13.5162C20.2579 12.7487 20.2579 11.2513 19.266 10.4838C16.2685 8.1644 12.9213 6.33625 9.34976 5.06777L8.69729 4.83603C7.44901 4.39269 6.1305 5.23725 5.96151 6.5258C5.48935 10.126 5.48935 13.874 5.96151 17.4742C6.1305 18.7628 7.44901 19.6073 8.69728 19.164L9.34976 18.9322C12.9213 17.6638 16.2685 15.8356 19.266 13.5162Z"/></svg>

  <svg v-if="name ==='podcastLink'" width="24" height="24" viewBox="0 0 24 24" fill="#59595B" xmlns="http://www.w3.org/2000/svg"><path d="M14.166 3.18862C12.7368 2.89579 11.2631 2.89579 9.83398 3.18862C9.39016 3.27956 9.04841 3.63482 8.97474 4.08184L8.92052 4.41083C8.47425 7.11868 8.47425 9.88132 8.92052 12.5892L8.97474 12.9182C9.04841 13.3652 9.39016 13.7204 9.83398 13.8114C11.2631 14.1042 12.7368 14.1042 14.166 13.8114C14.6098 13.7204 14.9515 13.3652 15.0252 12.9182L15.0794 12.5892C15.5257 9.88132 15.5257 7.11868 15.0794 4.41083L15.0252 4.08183C14.9515 3.63482 14.6098 3.27956 14.166 3.18862Z"/><path d="M4.89475 10.2574C5.30486 10.1993 5.68444 10.4847 5.74255 10.8948L6.19306 14.0741C6.31478 14.9331 6.9957 15.6047 7.85628 15.7146C10.6076 16.0661 13.3924 16.0661 16.1437 15.7146C17.0042 15.6047 17.6852 14.9331 17.8069 14.0741L18.2574 10.8948C18.3155 10.4847 18.6951 10.1993 19.1052 10.2574C19.5153 10.3155 19.8007 10.6951 19.7426 11.1052L19.292 14.2845C19.0755 15.8124 17.8644 17.007 16.3337 17.2025C15.1435 17.3546 13.9473 17.4437 12.75 17.47V21.5C12.75 21.9142 12.4142 22.25 12 22.25C11.5858 22.25 11.25 21.9142 11.25 21.5V17.47C10.0527 17.4437 8.85647 17.3546 7.66623 17.2025C6.13553 17.007 4.9244 15.8124 4.7079 14.2845L4.25739 11.1052C4.19928 10.6951 4.48463 10.3155 4.89475 10.2574Z"/></svg>

  <svg v-if="name ==='newsLink'" width="24" height="24" viewBox="0 0 24 24" fill="#59595B" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 21.5H6C4.067 21.5 2.5 19.933 2.5 18V4.94321C2.5 3.87566 3.55584 3.19886 4.4849 3.52142C4.61762 3.56749 4.74776 3.63397 4.87186 3.72261L5.0469 3.84765C5.91493 4.46766 7.08891 4.46492 7.95938 3.84316C9.17632 2.97391 10.8237 2.97391 12.0406 3.84316C12.9111 4.46492 14.0851 4.46766 14.9531 3.84765L15.1281 3.72261C16.1209 3.01347 17.5 3.72315 17.5 4.94321V12.5H21C21.4142 12.5 21.75 12.8358 21.75 13.25V18.75C21.75 20.2688 20.5188 21.5 19 21.5ZM17.75 14V18.75C17.75 19.4404 18.3096 20 19 20C19.6904 20 20.25 19.4404 20.25 18.75V14H17.75ZM13.5 9.75002C13.5 9.3358 13.1642 9.00002 12.75 9.00002H6.75C6.33579 9.00002 6 9.3358 6 9.75002C6 10.1642 6.33579 10.5 6.75 10.5H12.75C13.1642 10.5 13.5 10.1642 13.5 9.75002ZM12.5 12.75C12.5 12.3358 12.1642 12 11.75 12H6.75C6.33579 12 6 12.3358 6 12.75C6 13.1642 6.33579 13.5 6.75 13.5H11.75C12.1642 13.5 12.5 13.1642 12.5 12.75ZM12.75 15C13.1642 15 13.5 15.3358 13.5 15.75C13.5 16.1642 13.1642 16.5 12.75 16.5H6.75C6.33579 16.5 6 16.1642 6 15.75C6 15.3358 6.33579 15 6.75 15H12.75Z"/></svg>

  <svg v-if="name ==='infoLink'" width="24" height="24" viewBox="0 0 24 24" fill="#59595B" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.31673 3.76876C10.4043 3.42368 13.5957 3.42368 16.6832 3.76876C18.5096 3.97288 19.9845 5.41153 20.1994 7.24849C20.5686 10.4054 20.5686 13.5946 20.1994 16.7515C19.9845 18.5885 18.5096 20.0271 16.6832 20.2313C13.5957 20.5763 10.4043 20.5763 7.31673 20.2313C5.49035 20.0271 4.01545 18.5885 3.8006 16.7515C3.43137 13.5946 3.43137 10.4054 3.8006 7.24849C4.01545 5.41153 5.49035 3.97288 7.31673 3.76876ZM13 8.00001C13 8.55229 12.5523 9.00001 12 9.00001C11.4477 9.00001 11 8.55229 11 8.00001C11 7.44772 11.4477 7.00001 12 7.00001C12.5523 7.00001 13 7.44772 13 8.00001ZM12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16.5C12.75 16.9142 12.4142 17.25 12 17.25C11.5858 17.25 11.25 16.9142 11.25 16.5V11.5C11.25 11.0858 11.5858 10.75 12 10.75Z"/></svg>

  <svg v-if="name ==='vidoLink'" width="24" height="24" viewBox="0 0 24 24" fill="#59595B" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.96638 5.81396C9.31916 5.60359 11.6868 5.62195 14.036 5.86878L14.6438 5.93264C15.8037 6.05451 16.7372 6.93433 16.9314 8.07943L20.2098 6.33767C20.432 6.21964 20.6985 6.22085 20.9196 6.34091C21.1407 6.46097 21.2868 6.68388 21.3088 6.9345L21.3337 7.21834C21.6126 10.4 21.6126 13.6 21.3337 16.7817L21.3088 17.0655C21.2868 17.3161 21.1407 17.539 20.9196 17.6591C20.6985 17.7791 20.432 17.7804 20.2098 17.6623L16.9314 15.9206C16.7372 17.0657 15.8037 17.9455 14.6438 18.0674L14.036 18.1312C11.6868 18.3781 9.31916 18.3964 6.96638 18.1861L5.3846 18.0446C4.1927 17.9381 3.22968 17.0275 3.05657 15.8434C2.68394 13.2947 2.68394 10.7053 3.05657 8.15658C3.22968 6.97251 4.1927 6.06196 5.3846 5.95539L6.96638 5.81396ZM17.1164 14.3666C17.1966 14.3792 17.2762 14.4052 17.3519 14.4454L19.9069 15.8029C20.0882 13.2709 20.0882 10.7291 19.9069 8.19714L17.3519 9.55459C17.2762 9.59483 17.1966 9.62078 17.1164 9.63338C17.2566 11.208 17.2566 12.792 17.1164 14.3666Z" /></svg>

  <svg v-if="name ==='reklamaLink'" width="22" height="22" version="1.1"  fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 467.6 467.6" style="enable-background:new 0 0 467.6 467.6;" xml:space="preserve"><g><g><path d="M209.3,219.916c4.3-3.7,10.4-6.1,18.4-6.7v45.899c-9.8-3.1-16.5-6.699-20.2-9.8c-3.7-3.7-5.5-8-5.5-14.1C202.6,229.116,205,224.216,209.3,219.916z M388.6,72.416c-8-29.4-31.199-40.4-58.1-33L93,103.016h303.6L388.6,72.416zM467.6,178.916v204.4c0,26.3-20.8,47.101-47.1,47.101H47.1c-26.3,0-47.1-20.801-47.1-47.101v-204.4c0-26.3,20.8-47.1,47.1-47.1h373.3C446.2,131.815,467.6,153.216,467.6,178.916z M304.8,318.415c0-15.301-4.899-26.899-14.7-35.5c-9.8-8.601-24.5-14.699-44.699-19H244.8v-49.601c13.5,1.8,26.3,7.3,37.9,15.3l17.1-24.5c-17.1-11.6-35.5-18.4-55.1-19.6v-13.5h-17.1l0,0v12.9c-17.1,0.6-30.6,6.1-41.6,15.9c-10.4,9.8-15.9,22-15.9,37.3s4.9,26.9,14.1,34.3c9.2,8,23.9,14.102,43.5,19v51.4c-15.9-2.4-30.6-10.4-45.9-23.3l-19.6,23.3c19,16.5,41,26.3,64.9,28.8v19.602h17.1v-19c17.699-0.602,32.398-6.102,43.5-15.9C299.3,346.016,304.8,333.716,304.8,318.415z M244.8,295.815v47.7c8.601-0.601,15.3-3.101,20.2-7.3c4.899-4.301,7.3-9.2,7.3-15.301s-1.8-11-5.5-14.699C262.6,302.516,255.8,298.915,244.8,295.815z"/></g></g></svg>

  <svg v-if="name ==='menuIcon'" width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H19C19.4142 12.75 19.75 12.4142 19.75 12Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M19.75 7C19.75 6.58579 19.4142 6.25 19 6.25H5C4.58579 6.25 4.25 6.58579 4.25 7C4.25 7.41421 4.58579 7.75 5 7.75H19C19.4142 7.75 19.75 7.41421 19.75 7Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M19.75 17C19.75 16.5858 19.4142 16.25 19 16.25H5C4.58579 16.25 4.25 16.5858 4.25 17C4.25 17.4142 4.58579 17.75 5 17.75H19C19.4142 17.75 19.75 17.4142 19.75 17Z" /></svg>

  <svg v-if="name ==='menuCloseIcon'" width="30px" height="30px" fill="none" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"/></svg>

<!-- Иконки социальных сетей -->

  <svg v-if="name ==='vkSocial'" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.5035 18.381H19.222C18.3588 18.381 18.0992 17.682 16.5511 16.1339C15.2 14.8313 14.6292 14.6686 14.2868 14.6686C13.8145 14.6686 13.6847 14.7984 13.6847 15.4505V17.5021C13.6847 18.0573 13.5049 18.3825 12.0553 18.3825C10.6485 18.288 9.28422 17.8606 8.07493 17.1355C6.86564 16.4104 5.84592 15.4084 5.09975 14.212C3.32828 12.0071 2.0957 9.41934 1.5 6.65442C1.5 6.31196 1.62979 6.00234 2.28187 6.00234H4.56182C5.14822 6.00234 5.35933 6.26349 5.5892 6.86553C6.69633 10.1244 8.58534 12.9579 9.35158 12.9579C9.64556 12.9579 9.77379 12.8281 9.77379 12.0947V8.7389C9.67684 7.20799 8.86369 7.0782 8.86369 6.52463C8.87413 6.37862 8.94104 6.24243 9.05022 6.14492C9.1594 6.04742 9.30227 5.99628 9.44853 6.00234H13.0326C13.5221 6.00234 13.6847 6.24629 13.6847 6.83269V11.3613C13.6847 11.8508 13.8958 12.0134 14.0428 12.0134C14.3368 12.0134 14.5635 11.8508 15.1015 11.3128C16.2569 9.90381 17.2009 8.33401 17.9037 6.65286C17.9756 6.45092 18.1115 6.27804 18.2908 6.16058C18.4701 6.04313 18.6829 5.98755 18.8967 6.00234H21.1782C21.8616 6.00234 22.007 6.3448 21.8616 6.83269C21.032 8.6911 20.0055 10.4552 18.7997 12.0947C18.5542 12.47 18.4557 12.6655 18.7997 13.1049C19.0265 13.4473 19.8256 14.1151 20.3635 14.7499C21.1452 15.5297 21.7944 16.4319 22.2853 17.4208C22.4808 18.0557 22.154 18.381 21.5035 18.381Z" /></svg>

  <svg v-if="name ==='youtubeSocial'" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.98888 4.89052C10.3246 4.62955 13.6755 4.62955 17.0112 4.89052L19.2519 5.06582C20.5 5.16346 21.521 6.09886 21.7273 7.33363C22.2435 10.4231 22.2435 13.5769 21.7273 16.6664C21.521 17.9011 20.5 18.8365 19.2519 18.9342L17.0112 19.1095C13.6755 19.3704 10.3246 19.3704 6.98888 19.1095L4.74818 18.9342C3.50011 18.8365 2.47909 17.9011 2.27278 16.6664C1.75657 13.5769 1.75657 10.4231 2.27278 7.33363C2.47909 6.09886 3.50011 5.16346 4.74818 5.06582L6.98888 4.89052ZM10 14.4701V9.52986C10 9.29667 10.2544 9.15263 10.4543 9.27261L14.5713 11.7428C14.7655 11.8593 14.7655 12.1407 14.5713 12.2572L10.4543 14.7274C10.2544 14.8474 10 14.7033 10 14.4701Z" /></svg>

  <svg v-if="name ==='telegramSocial'" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.4831 19.7901L18.4838 19.7883L18.5012 19.7448L21.5 4.62577V4.57666C21.5 4.19996 21.3608 3.8711 21.0579 3.67384C20.7928 3.50118 20.4881 3.48889 20.2744 3.50511C20.0483 3.52226 19.8352 3.57868 19.6877 3.62564C19.6118 3.6498 19.5483 3.67307 19.5028 3.69068C19.48 3.69952 19.4615 3.70702 19.4481 3.7126L19.4349 3.71816L2.71508 10.2771L2.71048 10.2787C2.70145 10.282 2.68951 10.2865 2.67508 10.2921C2.64633 10.3033 2.60719 10.3193 2.56121 10.3402C2.47093 10.3811 2.34619 10.4442 2.21969 10.5308C2.00484 10.678 1.59572 11.0263 1.6648 11.5785C1.72202 12.0359 2.03733 12.3264 2.2499 12.4768C2.36384 12.5575 2.47286 12.6156 2.55269 12.6536C2.59313 12.6729 2.62749 12.6876 2.65308 12.698C2.66591 12.7032 2.67663 12.7074 2.68493 12.7106L2.69556 12.7145L2.70235 12.7171L5.62759 13.7019C5.61772 13.8854 5.63592 14.0725 5.68459 14.2572L7.14988 19.8156C7.32361 20.4746 7.91984 20.9337 8.60141 20.9332C9.21234 20.9328 9.75417 20.5632 9.98435 20.0118L12.272 17.5657L16.201 20.5779L16.2569 20.6023C16.6138 20.7581 16.9474 20.8074 17.2523 20.7658C17.5568 20.7242 17.7987 20.5965 17.9805 20.4511C18.1594 20.308 18.2819 20.1465 18.359 20.0249C18.3982 19.9631 18.4274 19.9088 18.4476 19.8679C18.4578 19.8474 18.4658 19.8301 18.4718 19.8165L18.4794 19.7991L18.4821 19.7927L18.4831 19.7901ZM7.13499 13.8748C7.10169 13.7485 7.15397 13.6151 7.26426 13.5451L17.1863 7.24417C17.1863 7.24417 17.7699 6.88987 17.749 7.24417C17.749 7.24417 17.8532 7.30673 17.5404 7.59846C17.2438 7.87554 10.4688 14.4166 9.78322 15.0785C9.74365 15.1167 9.72003 15.1605 9.70608 15.2137L8.60028 19.4332L7.13499 13.8748Z" /></svg>

  <svg v-if="name ==='instagramSocial'" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M6.76954 3.08147C10.2177 2.69609 13.7824 2.69609 17.2305 3.08147C19.1289 3.29364 20.6601 4.78942 20.8829 6.69447C21.2952 10.2195 21.2952 13.7805 20.8829 17.3055C20.6601 19.2106 19.1289 20.7064 17.2305 20.9185C13.7824 21.3039 10.2177 21.3039 6.76954 20.9185C4.87114 20.7064 3.33995 19.2106 3.11713 17.3055C2.70485 13.7805 2.70485 10.2195 3.11713 6.69447C3.33995 4.78942 4.87114 3.29364 6.76954 3.08147ZM17 6C16.4477 6 16 6.44772 16 7C16 7.55228 16.4477 8 17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6ZM7.25 12C7.25 9.37665 9.37665 7.25 12 7.25C14.6234 7.25 16.75 9.37665 16.75 12C16.75 14.6234 14.6234 16.75 12 16.75C9.37665 16.75 7.25 14.6234 7.25 12Z"/></svg>

  <svg v-if="name ==='facebookSocial'" width="26" height="26" viewBox="0 0 167.657 167.657" fill="none" _ngcontent-c10="" xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" style="enable-background:new 0 0 167.657 167.657;" version="1.1"   x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g _ngcontent-c10=""><path _ngcontent-c10="" d="M83.829,0.349C37.532,0.349,0,37.881,0,84.178c0,41.523,30.222,75.911,69.848,82.57v-65.081H49.626v-23.42h20.222V60.978c0-20.037,12.238-30.956,30.115-30.956c8.562,0,15.92,0.638,18.056,0.919v20.944l-12.399,0.006c-9.72,0-11.594,4.618-11.594,11.397v14.947h23.193l-3.025,23.42H94.026v65.653c41.476-5.048,73.631-40.312,73.631-83.154C167.657,37.881,130.125,0.349,83.829,0.349z"></path></g></svg>

  <svg v-if="name ==='podcastSocial'" width="28" height="28" viewBox="0 0 512 512" fill="none"  _ngcontent-c10="" enable-background="new 0 0 512 512"  id="Capa_1"   xmlns="http://www.w3.org/2000/svg"><g _ngcontent-c10=""><path _ngcontent-c10="" d="m408.429 239.071c0-8.284-6.716-15-15-15s-15 6.716-15 15c0 67.507-54.921 122.429-122.429 122.429s-122.429-54.921-122.429-122.429c0-8.284-6.716-15-15-15s-15 6.716-15 15c0 78.988 60.394 144.131 137.429 151.69v30.988h-15.125c-41.493 0-75.25 33.757-75.25 75.25 0 8.284 6.716 15 15 15h180.75c8.284 0 15-6.716 15-15 0-41.493-33.757-75.25-75.25-75.25h-15.125v-30.988c77.035-7.559 137.429-72.702 137.429-151.69z"></path><path _ngcontent-c10="" d="m211.87 150.625c8.284 0 15 6.716 15 15s-6.716 15-15 15h-46.182v30.25h46.182c8.284 0 15 6.716 15 15s-6.716 15-15 15h-46.182v.063c0 49.798 40.514 90.312 90.313 90.312s90.313-40.514 90.313-90.312v-.063h-46.184c-8.284 0-15-6.716-15-15s6.716-15 15-15h46.182v-30.25h-46.182c-8.284 0-15-6.716-15-15s6.716-15 15-15h46.182v-30.25h-46.182c-8.284 0-15-6.716-15-15s6.716-15 15-15h46.182v-.062c.001-49.799-40.514-90.313-90.312-90.313s-90.313 40.514-90.313 90.313v.062h46.182c8.284 0 15 6.716 15 15s-6.716 15-15 15h-46.182v30.25z"></path></g></svg>

  <svg v-if="name ==='zenSocial'" width="26" height="26" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <path fill="#red"
        d="M16.7 16.7c-2.2 2.27-2.36 5.1-2.55 11.3 5.78 0 9.77-.02 11.83-2.02 2-2.06 2.02-6.24 2.02-11.83-6.2.2-9.03.35-11.3 2.55M0 14.15c0 5.59.02 9.77 2.02 11.83 2.06 2 6.05 2.02 11.83 2.02-.2-6.2-.35-9.03-2.55-11.3-2.27-2.2-5.1-2.36-11.3-2.55M13.85 0C8.08 0 4.08.02 2.02 2.02.02 4.08 0 8.26 0 13.85c6.2-.2 9.03-.35 11.3-2.55 2.2-2.27 2.36-5.1 2.55-11.3m2.85 11.3C14.5 9.03 14.34 6.2 14.15 0c5.78 0 9.77.02 11.83 2.02 2 2.06 2.02 6.24 2.02 11.83-6.2-.2-9.03-.35-11.3-2.55">
    </path>
</svg>
</template>

<script>
  export default {
    name: 'MenuIcons',
    props: ['name']
  }
</script>

<style lang="sass">

</style>
