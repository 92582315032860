import useApi from '@/hooks/api'
import { ref } from 'vue'

export interface Playlist {
  adnow: number,
  stream_id: number,
  msItem: number,
  items: Track[]
}

export interface Track {
  artist: string,
  track: string,
  duration: string,
  imglarge: string,
  imgsmall: string,
  starttime: string,
  startdate: string
}

//api v4
// export interface Artist {
//   name: string
// }

// export interface Track {
//   artist: Artist,
//   name: string,
//   imglarge: string,
//   imgsmall: string,
//   starttime: string,
//   startdate: string
// }

export default async function getLastTracks() {
  const { response: playlist, request } = useApi<Playlist>(
    // '/api/v4/playlists/?stream_id=8'
    '/api/v3/vast/8/'
  )
  const loaded = ref(false)
  if (loaded.value === false) {
    await request()
    loaded.value = true
  }

  return { playlist }
}
