<template>

    <div class="streams__popup active grid blur" v-if="streamSelectVisible">
      <div class="stream grid" v-for="stream in streams" :key="stream.id"  @click="selectStream(stream)">
        <StreamsIcons :name="stream.icon"/>
        <div class="stream__title">{{stream.name}}</div>
      </div>
    </div>

</template>


<script>
import StreamsIcons from '@/components/StreamsIcons.vue'

export default {
  name: 'PlayerStreamSelect',
  components: { StreamsIcons },
  props: {
    streams: {
      type: Array,
      default(){
        return[]
      }
    },
    streamSelectVisible: {
      type: Boolean,
      default(){
        return[]
      }
    }
  },
  data(){
    return {}
  },
  methods: {
    selectStream(stream) {
      this.$emit('activeStream', stream)
      console.log(stream)
    }
  }
}
</script>


<style lang="sass" scoped>

@import "/styles/var.sass"
@import "vue-slider-component/theme/default.css"

.stream
  height: 100%
  width: 100%
  grid-template-columns: 40px 120px 40px
  align-items: center
  cursor: pointer
  grid-gap: 10px
  &__title
    margin-top: 0
    margin-bottom: 0
    font-weight: 500
    color: $fc_0
    z-index: 2

.stream::v-deep(svg)
  max-height: 25px
  fill: $fc_0

.streams__popup
  position: absolute
  top: 85px
  right: 18px
  width: 210px
  height: 200px
  padding: 10px
  grid-gap: 5px
  z-index: 9999
  border-radius: 15px
  background-color: rgba(10,10,10,0.6)
  box-shadow: 0px 0px 10px -3px #000
  visibility: hidden
  opacity: 0
  transition: .2s
  &.active
    visibility: visible
    opacity: 1
    transition: .2s

.blur
  backdrop-filter: saturate(180%) blur(10px)

</style>
