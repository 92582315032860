import { render } from "./PlayerComponent.vue?vue&type=template&id=b5d7e6de&scoped=true"
import script from "./PlayerComponent.vue?vue&type=script&lang=js"
export * from "./PlayerComponent.vue?vue&type=script&lang=js"

import "./PlayerComponent.vue?vue&type=style&index=0&id=b5d7e6de&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-b5d7e6de"
/* hot reload */
if (module.hot) {
  script.__hmrId = "b5d7e6de"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b5d7e6de', script)) {
    api.reload('b5d7e6de', script)
  }
  
  module.hot.accept("./PlayerComponent.vue?vue&type=template&id=b5d7e6de&scoped=true", () => {
    api.rerender('b5d7e6de', render)
  })

}

script.__file = "components/PlayerComponent.vue"

export default script