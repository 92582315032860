<template>
  <div class="vast_wrap grid">
    <a 
      id="vast_container"
      v-if="creativeVisible" 
      :href="creative.url" 
      class="vast_container" 
      target="_blank"
      @click="vastClickTracking()"
    >
      <img :src="creative.image">
    </a>
  </div>
  <div class="pl_wrapper">
    <div class="pl grid blur">

      <div 
        class="header__menu " 
        @click.prevent="mobileMenuOpen()"
        v-if="!mobileMenuVisible"
      >
        <MenuIcons :name="'menuIcon'"/>
      </div>
      <MenuMobile 
        v-if="mobileMenuVisible" 
        @mmClose="mobileMenuVisible=false"
      />

      <router-link :to="'/'" class="header__logo">
        <img class="header__logo_img" src="../assets/img/logo_title_g.svg" alt="">
      </router-link>
      <div class="pl__cur-playing grid">
        <div class=" grid">
          <img :src="track.imgsmall" alt="" onerror="this.src='/assets/img/player/blank_cover.png'" class="pl__cur-playing_img">
        </div>
        <div class="pl__cur-playing_name grid">
          <p class="cur-playing_artist">{{ track.artist }}</p>
          <p class="cur-playing_track">{{ track.name }}</p>
        </div>
      </div>

      <button class="pl__controls__mid-btn" @click="playlistModalVisible = true">
        <PlayerIcons :name="'playlistPlayer'"/>
      </button>

      <div class="pl__controls__pp grid" id="js-pl__controls__pp">
        <button class="pl__controls__pp_play grid active" id="play" @click.prevent="playerPlay()">
          <PlayerIcons :name="'playPlayer'"/>
        </button>
        <button class="pl__controls__pp_pause grid " id="pause" @click.prevent="playerStop()">
          <PlayerIcons :name="'pausePlayer'"/>
        </button>
      </div>
      
      <div class="pl__controls__mid-btn pl__controls__vol">
        <PlayerIcons :name="'volPlayer'"/>
        <div class="pl__controls__vol_slider">
          <vue-slider v-model="value"
            use-keyboard: = true
            min: = 0
            value: = 80
            step: = 5
            max: = 100
            v-on:change="playerVolumeSet">
          </vue-slider>
        </div>
      </div>

      <div class="social grid">
        <div class="menu__social grid">
          <a class="menu__social_item grid" href="https://vk.com/piterfm" target="_blank">
            <MenuIcons :name="'vkSocial'"/>
          </a>
          <a class="menu__social_item grid" href="https://www.youtube.com/channel/UCK5xbqYCjg_ifwlQxElt9QQ" target="_blank">
            <MenuIcons :name="'youtubeSocial'"/>
          </a>
          <a class="menu__social_item grid" href="https://t.me/radiopiterfm" target="_blank">
            <MenuIcons :name="'telegramSocial'"/>
          </a>
          <a class="menu__social_item grid" href="https://zen.yandex.ru/id/62416088f076fc1ee2d5abe5" target="_blank">
            <MenuIcons :name="'zenSocial'"/>
          </a>
          <!-- <a class="menu__social_item grid" href="https://www.instagram.com/piterfm_radio/" target="_blank">
            <MenuIcons :name="'instagramSocial'"/>
          </a>
          <a class="menu__social_item grid" href="https://www.facebook.com/piterfm1009" target="_blank">
            <MenuIcons :name="'facebookSocial'"/>
          </a> -->
        </div>
      </div>

    </div>

    <teleport to="body">
      <div class="modal grid " v-if="playlistModalVisible">
        <div class="modal__bg" @click="playlistModalVisible = false"></div>
        <PlayerPlaylistModal
          :playlist="playlist.playlist"
          :playlistModalVisible="playlistModalVisible"
          :activeStream="activeStream"
          @modalClose="playlistModalVisible=false"
        />
      </div>
    </teleport>

  </div>
</template>

<script>
  import { ref, reactive, computed} from 'vue'
  import getLastTracks from '@models/tracks'
  import { playerStop, playerPlay } from '@/use/playerHelpers'
  import { playerVolumeSet } from '@/use/playerHelpers'
  import { creativeExtract } from '@/helpers/vastCreativeExtract'
  import VueSlider from 'vue-slider-component'
  import PlayerStreamSelect from '@/components/PlayerStreamSelect'
  import PlayerPlaylistModal from '@/components/PlayerPlaylistModal'
  import PlayerIcons from '@/components/PlayerIcons.vue'
  import MenuIcons from '@/components/MenuIcons.vue'
  import MenuMobile from '@/components/MenuMobile.vue'
  import { useStore } from 'vuex'

  export default {
    name: 'PlayerComponent',
    components: { 
      VueSlider, 
      PlayerStreamSelect, 
      PlayerPlaylistModal, 
      PlayerIcons, 
      MenuIcons,
      MenuMobile
    },
    setup() {

      //get api and save to store
      const store = useStore()
      const onChangeStore = (key, value) => store.commit('setCurrentTrack', {key, value})     
      const getActiveTrack = () => getLastTracks().then((promise2Result) => {
        playlist.playlist = promise2Result.playlist.value
        onChangeStore('name', promise2Result.playlist.value.items[0].track || 'Питер FM')
        onChangeStore('artist', promise2Result.playlist.value.items[0].artist || '100.9')
        onChangeStore('imglarge', promise2Result.playlist.value.items[0].imglarge || '/assets/images/undef.jpg')
        onChangeStore('imgsmall', promise2Result.playlist.value.items[0].imgsmall || '/assets/images/undef.jpg')
      })
      getActiveTrack()
      setInterval( getActiveTrack, 5000 )

      //modal window
      const playlist = reactive({ playlist: [] })
      const playlistModalVisible = ref(false)
      const mobileMenuVisible = ref(false)
      const mobileMenuOpen = () => mobileMenuVisible.value = true
      
      //get data from store
      const currentTrack = computed(() => store.getters.getCurrentTrack)
      const track = computed(() => {
        return {
          name: currentTrack.value.name,
          artist: currentTrack.value.artist,
          imgsmall: currentTrack.value.imgsmall
        }
      })
      
      // vast logic
      const creative = ref({})
      const metrics = ref([])
      const creativeVisible = ref(false)
      const playerInst = document.getElementById('playerjs')

      const vastClickTracking = () => {
        metrics.value.forEach(el => fetch(el).catch((err)=> console.log('!!! ', err)))
      }

      playerInst.addEventListener('vast_xml', (e) => {
        creative.value = creativeExtract(e)
        //console.log('creative.value.trackers',creative.value.trackers)
        creative.value.trackers.forEach(e => metrics.value.push(e))
        // console.log('*',metrics)
        if (creative.value && creative.value.image) {
          document.getElementById('ad_wrapper').style.display = "none"
          creativeVisible.value = true
        }
        setTimeout(()=> metrics.value = [],60000)
        
         //console.log('creative.value',creative.value)
      });

      playerInst.addEventListener('vast_finish', () => {
        document.getElementById('ad_wrapper').style.display = "block"
        creativeVisible.value = false
        // metrics.value = []
        // console.log('vast_finish',metrics)
      });

      // setTimeout(()=> console.log('metrics sett',metrics),20000)

      return { 
        creativeVisible,
        creative,
        vastClickTracking,
        track, 
        playlist, 
        playerVolumeSet, 
        playerStop, 
        playerPlay, 
        mobileMenuVisible,
        mobileMenuOpen,
        value: 80,
        playlistModalVisible,
      }
    },
  }
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "/styles/modal.sass"
@import "vue-slider-component/theme/default.css"
.vast
  &_wrap
    width: 100%
    justify-items: center
    align-items: center
    img
      width: 100%
      max-width: 728px

.pl_wrapper
  @media (max-width: $size_m)
    margin: 0 -10px 0 -10px

.pl
  height: $header_height__l
  @media (max-width: $size_m)
    height: $header_height__m
  grid-template-columns: 1fr 50px 70px 50px 1fr
  box-sizing: content-box
  border-radius: 0 0 20px 20px
  align-items: center
  justify-items: center
  @media (max-width: $size_m)
    grid-template-columns: 60px 1fr 60px

.header__logo
  display: none
  cursor: pointer
  @media (max-width: $size_m)
    justify-content: center
    padding: 0
    grid-column: 2/3
    display: grid
  &_img
    width: 180px

.header__menu
  display: none
  height: 50px
  
  align-items: 
  @media (max-width: $size_m)
    display: grid

.pl::v-deep(svg)
  margin: auto
  fill: $fc_0
  cursor: pointer
  transition: 0.2s
  &:hover
    transition: 0.2s
    fill: white
    filter: drop-shadow($accent_shadow)

.pl__controls__pp
  justify-items: center
  overflow: hidden
  @media (max-width: $size_m)
    grid-column: 3/4
    grid-row: 1/2
    margin-left: -10px
  &_pause
    width: 50px
    height: 50px
    @media (max-width: $size_m)
      height: 40px
      width: 40px
  &_play
    height: 70px
    width: 70px
    @media (max-width: $size_m)
      height: 60px
      width: 60px
  button
    display: none
  .active
    display: block

.pl__controls__mid-btn
  margin: auto
  width: 22px
  height: 22px
  @media (max-width: $size_m)
    display: none

.pl__controls__vol
  position: relative
  padding: 10px
  &_slider
    position: absolute
    top: 7px
    left: 38px
    width: 100px
    opacity: 0
    visibility: hidden
    padding:
      left: 12px
      right: 12px
      top: 5px
      bottom: 5px
    backdrop-filter: saturate(180%) blur(10px)
    border-radius: 25px
    transition: .2s
    &:hover
      opacity: 1
      transition: .2s
      visibility: visible

.pl__controls__vol:hover .pl__controls__vol_slider
  opacity: 1
  visibility: visible
  transition: .2s

.pl__cur-playing
  grid-template-columns: 80px 1fr
  margin-left: 15px
  width: 100%
  @media (max-width: $size_m)
    display: none
  &_img
    margin: auto
    height: 65px
    width: 65px
    border-radius: 8px
    overflow: hidden
    box-shadow: $accent_light
    object-fit: cover
  &_name
    grid-template-rows: 20px 20px
    margin:
      left: 0
      right: 0
      top: auto
      bottom: auto
    padding: 0px 15px 0px 10px

.cur-playing_artist
  margin: 0
  font-weight: 700
  max-width: 100%
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.cur-playing_track
  color: $fc_1
  margin: 0
  max-width: 100%
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.pl__controls__vol_slider::v-deep(.vue-slider-process)
  background-color: $fc_0

.pl__controls__vol_slider::v-deep(.vue-slider-rail)
  background-color: $fc_2

.pl__controls__vol_slider::v-deep(.vue-slider-dot-tooltip-inner)
  background-color: $fc_0
  border-color: $fc_0
  color: black

.pl__controls__vol_slider::v-deep(.vue-slider-dot-handle)
  background-color: #e1e1e1
  transition: .2s
  &:hover
    box-shadow: 0 0 5px rgba($accent,1)
    transition: .2s

.social
  justify-items: right
  padding-right: 40px
  width: 100%
  @media (max-width: $size_m)
    display: none
.menu__social
  height: 35px
  grid-template-columns: repeat(4,35px)
  grid-gap: 10px
  svg
    fill: $fc_2
  &_item
    justify-content: center
    align-items: center
    cursor: pointer
    transition: 0.2s
    svg
      &:hover
        filter: drop-shadow($accent_shadow)
        transition: 0.2s
        fill: $fc_0

</style>
