// import Vue from 'vue'
// import Vuex from 'vuex'

import {createStore} from 'vuex'
import { track } from "./modules/track"

export default createStore({
  modules: {
    track
  },
})