import { render } from "./NewsItemMainView.vue?vue&type=template&id=1b8769ff&scoped=true"
import script from "./NewsItemMainView.vue?vue&type=script&lang=js"
export * from "./NewsItemMainView.vue?vue&type=script&lang=js"

import "./NewsItemMainView.vue?vue&type=style&index=0&id=1b8769ff&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-1b8769ff"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1b8769ff"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1b8769ff', script)) {
    api.reload('1b8769ff', script)
  }
  
  module.hot.accept("./NewsItemMainView.vue?vue&type=template&id=1b8769ff&scoped=true", () => {
    api.rerender('1b8769ff', render)
  })

}

script.__file = "components/NewsItemMainView.vue"

export default script