<template>

  <div class="modal__container grid">
    <div class="modal__header grid">
      <p class="modal__title" >
        Ранее в эфире
      </p>
      <div class="modal__close" @click.prevent="$emit('modalClose')"> 
        <MenuIcons :name="'menuCloseIcon'" /> 
      </div>
    </div>
    <div class="pl__cur-playing grid " v-for="item in playlist.items.slice(0, 6)" :key="item.id">
      <img class="pl__cur-playing_img" :src="item.imgsmall || '/assets/images/undef.jpg'" >
      <div class="pl__cur-playing_name grid" >
        <p class="pl__cur-playing_title pl__cur-playing_title-artist">{{item.artist}}</p>
        <p class="pl__cur-playing_title pl__cur-playing_title-track">{{item.track}}</p>
      </div>
      <div class="pl__cur-playing_time">
        <!-- {{formatDate(item.starttime )}} -->
        {{item.starttime.slice(0,5)}}
      </div>
    </div>
  </div>
  
</template>

<script>
import MenuIcons from '@/components/MenuIcons.vue'
export default {
  name: 'playlistModal',
  props: ['playlist','playlistModalVisible'],
  emits: ['modalClose'],
  components: { MenuIcons },
  setup(){
    const formatDate = (d) => {
      const date = new Date(d)
      const options = {  
        hour: "2-digit", 
        minute: "2-digit",
        timeZone: "UTC"
      }
      return date.toLocaleTimeString("ru", options)
    }
    return {
      formatDate
    }
  }
}
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "vue-slider-component/theme/default.css"

.modal
  &__header
    grid-template-columns: 30px auto 30px
    justify-items: center
    align-items: center
  &__close
    height: 30px
    width: 30px
    cursor: pointer
    grid-column: 3/4 
    &::v-deep(svg)
      fill: $fc_0
  &__container
    width: 450px
    border-radius: 20px
    box-shadow: 0px 0px 10px -3px #000
    backdrop-filter: saturate(180%) blur(10px)
    background-color: rgba(10,10,10,0.7)
    padding: 20px
    grid-gap: 10px
  &__title
    grid-column: 2/3 
    margin: 0 
    font-weight: 700
    font-size: 22px

.pl__cur-playing
  grid-template-columns: auto 1fr 50px
  grid-gap: 20px
  &_img
    object-fit: cover
    margin: auto
    height: 65px
    width: 65px
    border-radius: 8px
  &_name
    grid-template-rows: 20px 20px
    margin: auto 0 auto 0
  &_title
    margin: 0
    max-width: 100%
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    &-artist
      color: $fc_0
    &-track
      color: $fc_1
  &_time
    margin: auto


</style>
