<template>
  <div class="content grid">
    <div class="current__title">Сейчас в эфире</div>
    <div class="current grid ">
      <img class= "current__img" :src="track.imglarge">
      <div class="current__desc">
        <p class="current__desc_artist">{{track.artist}}</p>
        <p class="current__desc_name">{{track.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  export default {
    name: 'CurrentComponent',
    components: {
    },
    setup() {
      const store = useStore()

      const currentTrack = computed(() => {
        return store.getters.getCurrentTrack
      })  

      const track = computed(() => {
        return {
          name: currentTrack.value.name,
          artist: currentTrack.value.artist,
          imglarge: currentTrack.value.imglarge
        }
      })

      return {
        track
      }
	  },
  }

</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
.content
  width: 100%
  height: 100%
  justify-items: center
  align-items: center
  grid-template-rows: 70px auto
  box-shadow: 0 0 10px -5px #000
  background: $widget_bg
  background-size: cover
  padding: 16px
  box-sizing: border-box
  
.current
  padding-bottom: 60px
  &__title
    font-size: 18px
    font-weight: 700
    color: $fc_2
    text-shadow: $accent_light
  &__img
    width: 250px
    height: 250px
    object-fit: cover
    border-radius: 12px
    box-shadow: $accent_light
    margin: auto
  &__desc
    margin-top: 20px
    text-align: center
    overflow: hidden
    overflow-wrap: anywhere
    width: 100%
    &_name
      color: $fc_1

</style>
