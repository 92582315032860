import { render } from "./VideoModal.vue?vue&type=template&id=7904a58d&scoped=true"
import script from "./VideoModal.vue?vue&type=script&lang=js"
export * from "./VideoModal.vue?vue&type=script&lang=js"

import "./VideoModal.vue?vue&type=style&index=0&id=7904a58d&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-7904a58d"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7904a58d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7904a58d', script)) {
    api.reload('7904a58d', script)
  }
  
  module.hot.accept("./VideoModal.vue?vue&type=template&id=7904a58d&scoped=true", () => {
    api.rerender('7904a58d', render)
  })

}

script.__file = "components/VideoModal.vue"

export default script