<template>
  <div class="slider">
    <splide
      :options="splOtions"
      :slides="slideItems"
    >
      <splide-slide 
        v-for="slideItem in slideItems" 
        :key="slideItem.position"
      >
        <router-link v-if="checkInnerUrl(slideItem.url)" class="slider-image-wrapper" :to="slideItem.url" target="blank">
          <img class="slider-image"
            :src="fullPath(slideItem.imgPath)"/>
        </router-link>
        <a v-if="!checkInnerUrl(slideItem.url)" class="slider-image-wrapper" :href="slideItem.url" target="blank">
          <img class="slider-image"
            :src="fullPath(slideItem.imgPath)"/>
        </a>
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import { ref } from 'vue'
import { getSlides } from '@models/slides'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { fullPath } from '@/helpers/fullPath'
export default {
  name: 'SliderComponent',
  emits: ['loaded'],
  components: {
    Splide,
    SplideSlide,
  },
  setup(props,  { emit }) {
    const splOtions = {
      type: 'loop',
      perPage: 1,
      perMove: 1,
      arrows: true,
      autoplay: true,
      height: '100%',
    }

    const slideItems = ref([])

    const checkInnerUrl = (url) => {
      if (url.slice(0,5) === "http:" || url.slice(0,6) === "https:") {
        return false
      } return true
    }

    getSlides()
      .then( (promise2Result) => {
        slideItems.value = promise2Result.slides.value.items
        emit('loaded')
      })
      .catch(err => console.log(err))

    return { 
      checkInnerUrl,
      slideItems, 
      splOtions, 
      fullPath 
    }
  }
}
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "@splidejs/splide/dist/css/themes/splide-default.min.css"

@mixin slider-size 
  width: 100%
  aspect-ratio: 16 / 9
  @media (max-width: $size_m)
    aspect-ratio: 4 / 3

.slider
  border-radius: 20px
  overflow: visible
  box-shadow: 0 0 10px -5px #000

.slider::v-deep(.splide__track)
  @include slider-size 
  border-radius: 20px
  overflow: hidden

.slider::v-deep(.splide--loop)
  @include slider-size 
  overflow: hidden

.slider::v-deep(.slider-image)
  @include slider-size 
  object-fit: cover

.slider::v-deep(.splide__arrow)
  opacity: .3
  background-color: transparent
  transition: .2s
  &:hover
    opacity: .8
    transition: .2s
  svg
    fill: white
   
.component-wrapper::v-deep(.splide__arrow--prev)
  left: auto
  right: 50px
</style>