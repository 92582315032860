import { render } from "./VideoItem.vue?vue&type=template&id=6c09473a&scoped=true"
import script from "./VideoItem.vue?vue&type=script&lang=js"
export * from "./VideoItem.vue?vue&type=script&lang=js"

import "./VideoItem.vue?vue&type=style&index=0&id=6c09473a&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-6c09473a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "6c09473a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6c09473a', script)) {
    api.reload('6c09473a', script)
  }
  
  module.hot.accept("./VideoItem.vue?vue&type=template&id=6c09473a&scoped=true", () => {
    api.rerender('6c09473a', render)
  })

}

script.__file = "components/VideoItem.vue"

export default script