import useApi from '@/hooks/api'
import { ref } from 'vue'


export interface News {
  items: New[],
  count: number
}
export interface New {
  id: number,
  annotation_text: string,
  annotation_img: string,
  body: string
}

export default async function getNews(offset,limit) {
  const { response: news, request } = useApi<News>(
    `/api/v1/news?offset=${offset}&limit=${limit}`
  )
  const loaded = ref(false)
  if (loaded.value === false) {
    await request()
    loaded.value = true
  }

  return { news }
}
