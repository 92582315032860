<template>
  <div>

    <div class="component-wrapper">
      <splide 
        :options="splOptions"
        :slides="podcastItems"
      >
    		<splide-slide v-for="item in podcastItems" :key="item.position">
          <router-link
            class="widget-item podcast-item grid"
            :to="`/podcasts/` + item.slug"
          >
            <div 
              class="podcast-item__img" 
              :style="{
                backgroundImage : `url(${fullPath(encodeURI(item.img))})`
              }"
            >
            </div>
            <p class="podcast-item__name">{{item.name}}</p>
          </router-link>
    		</splide-slide>
    	
      </splide>
      <div class="component__link-wrap">
        <router-link to="/podcasts" class="component__link">
          Все подкасты
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { Splide, SplideSlide } from '@splidejs/vue-splide'
  import getPodcasts from '@models/podcasts'  
  import { ref } from 'vue'
  import { fullPath } from '@/helpers/fullPath'

  export default {
    name: 'PodcastComponent',
    emits: ['loaded'],
    components: {
      Splide,
      SplideSlide,
    },
    setup (props,  { emit }) {
      const podcastItems = ref([])
      
      const renderPodcasts = (promise2Result) => {
        podcastItems.value =  podcastItems.value.concat(promise2Result.podcasts.value.items)
        emit('loaded')
      }

      getPodcasts(0, 7)
        .then( (promise2Result) => renderPodcasts(promise2Result) )
        .catch(err => console.log(err))

      return {
        fullPath,
        podcastItems,
        splOptions: {
          rewind : true,
          autoWidth: true,
          pagination: false,
          arrows: true,
          gap:'20px'
        },
      }
    },
  }
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "@splidejs/splide/dist/css/themes/splide-default.min.css"

.component-wrapper
  margin-right: -20px
  margin-left: -10px
  @media (max-width: $size_m)
    margin-right: -10px

.podcast-item
  text-decoration: none
  width: 250px
  height: 100%
  cursor: pointer
  grid-template-rows: 230px auto auto
  &__name
    font-size: 16px 
    margin: 16px
    color: $fc_1
    text-align: center
  &__img
    height: 230px
    border-radius: 12px
    transition: .2s
    background-position: center
    background-size: cover

.component-wrapper::v-deep(.splide__track)
  padding: 10px 0px 10px 10px

.component-wrapper::v-deep(.splide__arrow)
  top: -30px 
  background-color: $bglvl_2
  // @media (max-width: $size_s)
  //   display: none
  svg
    fill: $fc_0
    transition: .2s
    &:hover
      fill: white
      transition: .2s
.component-wrapper::v-deep(.splide__arrow--prev)
  left: auto
  right: 50px
</style>
