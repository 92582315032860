<template>
 
  <div class="MainView grid" >
    <div class="preloader grid" v-if="!readyAllComponents">
      <img class="preloader__img" src="../assets/img/bars.svg" >
    </div>
    <section class=" main-section grid">
      <div class="main-section__comp-1">
        <SliderComponent @loaded="readySliderComponent=true"/>
      </div>
      <div class="main-section__comp-2 grid" >
        <CurrentComponent/>
      </div>
    </section>
    <section class="widget-section grid">
      <h2 class="component-title">
        <router-link to="/news" class="component-title">Новости</router-link>
      </h2>
      <div class="comp-4 widget-section__comp">
        <NewsComponent @loaded="readyNewsComponent=true"/>
      </div>
    </section>
    <section class="widget-section grid">
      <h2 class="component-title">
        <router-link to="/podcast" class="component-title">Подкасты</router-link>
      </h2>
      <div class="comp-4 widget-section__comp">
        <PodcastComponent @loaded="readyPodcastComponent=true"/>
      </div>
    </section>
    <section class="widget-section grid">
      <h2 class="component-title">
        <router-link to="/video" class="component-title">Видео</router-link>
      </h2>
      <div class="widget-section__comp-5 widget-section__comp">
        <VideoComponent @loaded="readyVideoComponent=true"/>
      </div>
    </section>
  </div>
  
</template>

<script>
import SliderComponent from '/components/SliderComponent.vue'
import CurrentComponent from '/components/CurrentComponent.vue'
import PodcastComponent from '/components/PodcastComponent.vue'
import VideoComponent from '/components/VideoComponent.vue'
import NewsComponent from '/components/NewsComponent.vue'
import PreloaderView from '@/components/PreloaderView.vue'
import { ref, computed } from 'vue'

export default {
  name: 'MainView',
  components: {
    SliderComponent,
    CurrentComponent,
    PodcastComponent,
    VideoComponent,
    NewsComponent,
    PreloaderView
  },
  setup(){
    const readyVideoComponent = ref(false)
    const readyPodcastComponent = ref(false)
    const readyNewsComponent = ref(false)
    const readySliderComponent = ref(false)
    const readyAllComponents = computed(() => readyVideoComponent.value && readyPodcastComponent.value && readyNewsComponent.value && readySliderComponent.value)

    return {
      readyAllComponents,
      readyPodcastComponent,
      readyNewsComponent,
      readyVideoComponent,
      readySliderComponent
    }
  }
}
</script>

<style lang="sass" scoped>
@import "/styles/var.sass"
@import "/styles/widgets.sass"

.MainView
  position: relative
  grid-gap: 50px
  grid-template-rows: max-content auto auto auto
  @media (max-width: $size_m)
    grid-gap: 35px

.main-section
  grid-template-columns: minmax(0, 1fr) max-content
  grid-gap: 20px
  @media (max-width: 1500px)
    grid-template-columns: minmax(0, 1fr)
  &__comp-1
    height: max-content
  &__comp-2
    overflow: hidden
    width: 300px
    height: 100%
    border-radius: 20px
    @media (max-width: 1550px)
      display: none

.widget-section
  width: 100%
  grid-template-columns: minmax(0, 1fr)
  &__comp
    width: 100%

.component-title
  color: $fc_0
  text-decoration: none
  font-size: 45px
  margin: 0px 0px 10px 0px
  @media (max-width: $size_m)
    font-size: 35px

.preloader
  position: absolute
  width: 100%
  height: 100%
  justify-content: center
  background-color: $bglvl_1
  z-index: 999
  padding: 20px
  margin-left: -20px
  margin-top: -10px
  @media (max-width: $size_m)
    padding: 10px
  &__img
    margin-top: 200px
    width: 70px
</style>
