import { render } from "./NewsComponent.vue?vue&type=template&id=ed12adc2&scoped=true"
import script from "./NewsComponent.vue?vue&type=script&lang=js"
export * from "./NewsComponent.vue?vue&type=script&lang=js"

import "./NewsComponent.vue?vue&type=style&index=0&id=ed12adc2&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-ed12adc2"
/* hot reload */
if (module.hot) {
  script.__hmrId = "ed12adc2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ed12adc2', script)) {
    api.reload('ed12adc2', script)
  }
  
  module.hot.accept("./NewsComponent.vue?vue&type=template&id=ed12adc2&scoped=true", () => {
    api.rerender('ed12adc2', render)
  })

}

script.__file = "components/NewsComponent.vue"

export default script