import useApi from '@/hooks/api'
import { ref } from 'vue'

export interface Videos {
  items: Video[],
  count: number
}

export interface Video {
  name: string,
  title: string,
  youtube_link: string,
  preview_img: string,
  youtube_embed: string,
  post_start: string
}

export default async function getVideos(offset,limit) {
  const { response: videos, request } = useApi<Videos>(
    `/api/v1/videos?offset=${offset}&limit=${limit}`,
  )
  const loaded = ref(false)
  if (loaded.value === false) {
    await request()
    loaded.value = true
  }
  return { videos }
}
