import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'

import MainView from '@/views/MainView.vue'
const AdvertisingView = () => import('@/views/AdvertisingView.vue')
const AboutView = () => import('@/views/AboutView.vue')
const VideoView = () => import('@/views/VideoView.vue')
const PathNotFoundView = () => import('@/views/PathNotFoundView.vue')

const NewsLayoutView = () => import('@/views/NewsLayoutView.vue')
const NewsListView = () => import('@/views/NewsListView.vue')
const BlankNewView = () => import('@/views/BlankNewView.vue')

const PodcastsLayoutView = () => import('@/views/PodcastsLayoutView.vue')
const PodcastsView = () => import('@/views/PodcastsView.vue')
const PodcastBlankView =() => import('@/views/PodcastBlankView.vue')

const HeroLayoutView =() => import('@/views/HeroLayoutView.vue')
const HeroMainView =() => import('@/views/HeroMainView.vue')
const HeroTrackView =() => import('@/views/HeroTrackView.vue')


const routerHistory = createWebHistory()
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: MainView,
  },
  {
    path: '/podcasts',
    component: PodcastsLayoutView,
    children:[
      { 
        path: '', 
        component: PodcastsView,
      },
      { 
        path: ':slug', 
        component: PodcastBlankView,
      }
    ]
  },
  {
    path: '/video',
    component: VideoView,
  },
  {
    path: '/about',
    component: AboutView,
  },
  {
    path: '/news',
    component: NewsLayoutView,
    children:[
      { 
        path: '', 
        component: NewsListView,
      },
      { 
        path: ':slug', 
        component: BlankNewView,
      }
    ]
  },
  {
    path: '/kinohero',
    component: HeroLayoutView,
    children:[
      { 
        path: '', 
        component: HeroMainView,
      },
      { 
        path: ':slug', 
        component: HeroTrackView,
      }
      // konchitsya_leto
      // v_nashih_glazah
      // zvezda_po_imeni_solnce
      // kogda_tvoya_devushka_bolna
      // posledniy_geroy 
      // sledi_za_soboy
      // kukushka
      // pachka_sigaret
      // pechal
      // trolleybus
      // stuk
      // gruppa_krovi
      // peremen
      // mama_anarhiya
      // alyminievie_ogurci
      // videli_noch
      // vosmiklassnica
      // kamchatka
      // prohozhiy
      // spokoynaya_noch
    ]
  },
  { 
    path: '/advertising', 
    component: AdvertisingView,
  },
  { 
    path: '/podcasts/blank', 
    component: PodcastBlankView,
  },
  { 
    path: '/404', 
    component: PathNotFoundView,
  },
  { 
    path: '/:catchAll(.*)', 
    redirect: '/404' },
]

const router = createRouter({
  history: routerHistory,
  routes: routes,
  linkActiveClass: 'active',
  scrollBehavior() {
    return { 
      top: 0
    }
  }
})
export {router} 
