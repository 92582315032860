import { render } from "./SliderComponent.vue?vue&type=template&id=126aee91&scoped=true"
import script from "./SliderComponent.vue?vue&type=script&lang=js"
export * from "./SliderComponent.vue?vue&type=script&lang=js"

import "./SliderComponent.vue?vue&type=style&index=0&id=126aee91&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-126aee91"
/* hot reload */
if (module.hot) {
  script.__hmrId = "126aee91"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('126aee91', script)) {
    api.reload('126aee91', script)
  }
  
  module.hot.accept("./SliderComponent.vue?vue&type=template&id=126aee91&scoped=true", () => {
    api.rerender('126aee91', render)
  })

}

script.__file = "components/SliderComponent.vue"

export default script