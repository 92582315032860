<template>
  <div class="main__grid grid">
    <Menu></Menu>
    <MainContainer></MainContainer>
    <!-- <footer></footer> -->
  </div>
</template>

<script>
  import Menu from '@/components/Menu.vue'
  import MainContainer from '@/components/MainContainer.vue'

  export default {
    name: 'App',
    components: {
      Menu,
      MainContainer,
    }
  }
</script>
<style lang="sass">
@import "/styles/var.sass"
@import "/styles/scrollbar.sass"
@import "~normalize.css"
@import "/styles/fonts/stylesheet.css"
@import "/styles/View.sass"
@import "/styles/widgets.sass"
@import "/styles/normalizeTags.sass"
@import "/styles/popup.sass"

.grid
  display: grid

body
  background-color: $bglvl_0
  color: $fc_0
  font-family: HelveticaNeueCyr
  font-weight: 400
  min-width: 330px

.main__grid
  border: 0px solid red
  max-width: 1700px
  margin: auto
  padding-right: 20px
  grid-template-columns: 240px auto
  @media (max-width: $size_l)
    grid-template-columns: 60px auto
    padding: 0
  @media (max-width: $size_m)
    grid-template-columns: 100%
    padding: 0

</style>
