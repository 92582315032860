export const track = {
  state: {
    current: {
      artist: '100.9',
      name: 'Питер FM',
      imglarge: '/assets/images/undef.jpg',
      imgsmall: '/assets/images/undef.jpg'
    }
  },
  mutations: {
    setCurrentTrack: (state, e) => {
      if(e.value) {
        state.current[e.key] = e.value
      }
      // console.log(e.value)
      // console.log('state.current:::',state.current)
    }
  },
  // actions: {
  // },
  getters: {
    getCurrentTrack: state => {
      return state.current
    }
  } 
}



// {
// "adnow":0,
// "stream_id":8,
// "msItem":"",
// "items":[{
//   "starttime":"2000-01-01T10:17:38.000Z",
//   "startdate":"2022-03-11",
//   "track":{
//     "name":"ВСЕГО ХОРОШЕГО",
//     "duration":"00:02:52",
//     "imgsmall":"https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/8f/67/b4/8f67b4cd-1ade-560b-b417-0fa63a226949/source/300x300bb.jpg",
//     "imglarge":"https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/8f/67/b4/8f67b4cd-1ade-560b-b417-0fa63a226949/source/600x600bb.jpg",
//     "artist":{
//       "name":"СПЛИН"
//     }
//   }
// }]
// }