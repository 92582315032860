// import { ref } from 'vue'
// function playerToggle_old(){
//   // console.log('player toggled')
//   let audioElem = document.getElementById('js-audio')
//   // let playBtnElem = document.getElementById('play')
//   // let pauseBtnElem = document.getElementById('pause')
//   // // let controlsBtnElem = document.getElementById('js-pl__controls')
//   // playBtnElem.classList.toggle('active')
//   // pauseBtnElem.classList.toggle('active')
//   audioElem.paused ? audioElem.play() : audioElem.pause()
// }

function playerToggle(){
  window.playerjs.api('toggle')
  playerBtnToggle()
}

function playerStop(){
  window.playerjs.api('stop')
  playerBtnToggle()
}

function playerPlay(){
  window.playerjs.api('play')
  playerBtnToggle()
}

function playerBtnToggle(){
  let playBtnElem = document.getElementById('play')
  let pauseBtnElem = document.getElementById('pause')
  playBtnElem.classList.toggle('active')
  pauseBtnElem.classList.toggle('active')
}

function playerVolumeSet(e){
  // console.log('==>>>',e,'<==')
  window.playerjs.api("volume",e/100)
}

function playerStreamIdSet(){

}

export { playerToggle, playerStop, playerPlay, playerBtnToggle, playerVolumeSet }
