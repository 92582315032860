export function creativeExtract (e) {
  if (e.info) {
    let image = ""
    let url = ""
    let trackers = []

    const xml = unescape(e.info)
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(xml,'text/xml')
    const wrapperResource = xmlDoc.getElementsByTagName('Wrapper')

    if (wrapperResource && wrapperResource[0]) {
      const wrapperTrackers = Array.from(xmlDoc.getElementsByTagName('ClickTracking'))
      wrapperTrackers.forEach(e => {
        trackers.push(e.textContent.replace(/\s/g, ""))
      })
    }

    const allCreatives  = xmlDoc.getElementsByTagName('Companion') 

    if (allCreatives.length > 0) {
      const allCreativesFiltered = Array.from(allCreatives).filter( e => e.attributes.width.value == "728" && e.attributes.height.value == "90" );
      if (allCreativesFiltered.length > 0) {
        image = allCreativesFiltered[0].getElementsByTagName('StaticResource')[0].textContent.replace(/\s/g, "")
        url = allCreativesFiltered[0].getElementsByTagName('CompanionClickThrough')[0].textContent.replace(/\s/g, "")
        trackers =[]
        const clickTrackings = Array.from(allCreativesFiltered[0].getElementsByTagName('CompanionClickTracking'))
        clickTrackings.forEach(e => {
          trackers.push(e.textContent.replace(/\s/g, ""))
        });
      }
    }

    return {
      image,
      url,
      trackers
    }
  }
}