import { render } from "./StreamsIcons.vue?vue&type=template&id=1678c542"
import script from "./StreamsIcons.vue?vue&type=script&lang=js"
export * from "./StreamsIcons.vue?vue&type=script&lang=js"

import "./StreamsIcons.vue?vue&type=style&index=0&id=1678c542&lang=sass"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1678c542"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1678c542', script)) {
    api.reload('1678c542', script)
  }
  
  module.hot.accept("./StreamsIcons.vue?vue&type=template&id=1678c542", () => {
    api.rerender('1678c542', render)
  })

}

script.__file = "components/StreamsIcons.vue"

export default script