import { render } from "./MainView.vue?vue&type=template&id=25c6bc2d&scoped=true"
import script from "./MainView.vue?vue&type=script&lang=js"
export * from "./MainView.vue?vue&type=script&lang=js"

import "./MainView.vue?vue&type=style&index=0&id=25c6bc2d&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-25c6bc2d"
/* hot reload */
if (module.hot) {
  script.__hmrId = "25c6bc2d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('25c6bc2d', script)) {
    api.reload('25c6bc2d', script)
  }
  
  module.hot.accept("./MainView.vue?vue&type=template&id=25c6bc2d&scoped=true", () => {
    api.rerender('25c6bc2d', render)
  })

}

script.__file = "views/MainView.vue"

export default script