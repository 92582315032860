import { render } from "./PodcastComponent.vue?vue&type=template&id=739ddf34&scoped=true"
import script from "./PodcastComponent.vue?vue&type=script&lang=js"
export * from "./PodcastComponent.vue?vue&type=script&lang=js"

import "./PodcastComponent.vue?vue&type=style&index=0&id=739ddf34&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-739ddf34"
/* hot reload */
if (module.hot) {
  script.__hmrId = "739ddf34"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('739ddf34', script)) {
    api.reload('739ddf34', script)
  }
  
  module.hot.accept("./PodcastComponent.vue?vue&type=template&id=739ddf34&scoped=true", () => {
    api.rerender('739ddf34', render)
  })

}

script.__file = "components/PodcastComponent.vue"

export default script